<!-- src/pages/InstructPage.vue -->

<template>
  <v-navigation-drawer 
    expand-on-hover 
    rail
    class="navigation-drawer"
    rail-width="64"
    width="350"
    v-if="!$vuetify.display.mobile"
  >
    <InstructNavigation
      :userPhoto="userPhoto"
      :userName="userName"
      :truncatedUserEmail="truncatedUserEmail"
      @openAddStudent="openAddStudent"
      @openUserProfile="openUserProfile"
      @openAccountSettings="openAccountSettings"
      @signOut="signOut"
    />  
  </v-navigation-drawer>

  <v-app-bar class="top-navigation-bar" density="compact">
    <v-row class="d-flex align-center">
      <v-col cols="2" class="d-flex justify-center">
        <v-menu
          v-model="menu"
          location="end"
          v-if="$vuetify.display.mobile"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon color="primary" v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card" width="350">
            <InstructNavigation
              :userPhoto="userPhoto"
              :userName="userName"
              :truncatedUserEmail="truncatedUserEmail"
              @openAddStudent="openAddStudent"
              @openUserProfile="openUserProfile"
              @openAccountSettings="openAccountSettings"
              @signOut="signOut"
            />
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="8" class="d-flex justify-center">
        <v-tabs v-model="activeTab" color="primary">
          <v-tab @click="handleChatClick">Chat</v-tab>
          <v-tab @click="handleLogClick">Log</v-tab>
          <v-tab>Instruct</v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="!$vuetify.display.mobile" cols="2" class="d-flex justify-center">
        <v-btn 
          prepend-icon="mdi-account-plus"
          color="primary"
          @click="openAddStudent"
        >
          Add Student
        </v-btn>
      </v-col>
      <v-col v-else cols="2" class="d-flex justify-center">
        <v-btn 
          icon="mdi-account-plus"
          variant="text"
          color="primary"
          @click="openAddStudent"
        >
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-container class="card-container" v-if="!isLoading && !showStudentDashboard && students.length">
    <v-row>
      <v-col
        v-for="student in students"
        :key="student"
        :cols="12" 
        :sm="6"
        :lg="4"
      >
        <v-card
          class="card-item"
          variant="tonal"
          color="primary"
          @click="openStudentDashboard(student)"
        >
          <v-container>
            <v-row class="card-row mx-1">
              <v-col cols="2" class="card-avatar">
                <v-avatar class="avatar-icon">
                  <v-img v-if="student.photoUrl" :src="student.photoUrl"></v-img>
                  <v-icon v-else icon="mdi-account-circle"></v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="10" class="card-info">
                <v-card-title class="student-name" v-if="student.name"> {{ student.name }} </v-card-title>
                <v-card-title class="name-placeholder" v-else> Unknown Student </v-card-title>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row dense class="card-details">
              <v-col cols="12">
                <v-card-text v-if="student.trainingCourse" class="rating-chip">{{ student.trainingCourse }} </v-card-text >
                <v-card-text  v-else class="rating-chip"> No training course selected </v-card-text >
              </v-col>
              <v-col cols="12">
                <v-card-text class="card-email"> <strong>Email:</strong> {{ student.email }} </v-card-text>
              </v-col>
              <v-col cols="12">
                <v-card-text v-if="student.trainingPath" class="path-text"> <strong>Training Path:</strong> {{ student.trainingPath }} </v-card-text>
                <v-card-text v-else class="path-text"> <strong>Training Path:</strong> No training path selected </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="!isLoading && students.length == 0" class="placeholder-container">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
          src="@/assets/info-placeholder.png"
          max-width="200"
          class="mx-auto mb-3 rounded"
        ></v-img>
        <v-card-text class="placeholder-text">No students to display. Add a new student to get started!</v-card-text>
      </v-col>
    </v-row>
  </v-container>

  <v-overlay 
    v-model="isLoading" 
    class="align-center justify-center"
    persistent
  >
  <v-row justify="center">
    <v-col cols="12" class="loading-indicator">
        <v-progress-circular
            indeterminate
            color="primary"
            size="100"
            width="5"
        > <v-icon>mdi-airplane</v-icon>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>
  
  <StudentDashboard 
    v-if="showStudentDashboard" 
    ref="studentDetails" 
    @resetStudentDashboard="resetStudentDashboard()"
    @updateSuccessful="() => showSnackbar('Training details updated', 'info')"
    @updateFailed="() => showSnackbar('Training details failed to update', 'error')"
  />
  <AddStudent 
    ref="newStudentOverlay" 
    @studentAdded="handleStudentAdded"
    @studentError="handleStudentError"
    @inviteSent="handleInviteSent"
    @inviteFailed="handleInviteFailed"
  />
  <UserProfile ref="userProfile" @profileUpdated="() => showSnackbar('User profile updated', 'info')"/>
  <AccountSettings
    :userEmail="userEmail"
    :userName="userName"
    ref="accountSettings" 
    @settingsUpdated="() => showSnackbar('Account settings updated', 'info')"
  />
  <SnackbarNotification 
    :message="snackbar.message" 
    :color="snackbar.color" 
    :timeout="snackbar.timeout" 
    location="top right"
  />
</template>

<script>
import { getStudentsForInstructor } from '@/services/api';
import InstructNavigation from '@/components/instruct/InstructNavigation.vue';
import StudentDashboard from "@/components/instruct/StudentDashboard.vue";
import AddStudent from "@/components/instruct/AddStudent.vue";
import UserProfile from "@/components/other/UserProfile.vue";
import AccountSettings from "@/components/other/AccountSettings.vue";
import SnackbarNotification from "@/components/other/SnackbarNotification.vue";

export default {
  name: 'InstructPage',
  components: {
    InstructNavigation,
    StudentDashboard,
    AddStudent,
    UserProfile,
    AccountSettings,
    SnackbarNotification
  },
  data () {
    return {
      defaultAvatar: require('@/assets/default-avatar.png'),
      assistantAvatar: require('@/assets/orb-animation.png'),
      isLoading: false,
      menu: false,
      activeTab: 2,
      snackbar: {
        message: '',
        color: '',
        timeout: null,
      },
      students: [],
      showStudentDashboard: false,
      selectedStudent: null,
    }
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch('fetchUser'),
      this.loadStudents()
    ]);
    if (!this.isLoadingUser) {
      this.isLoading = false;
    }
  },
  methods: {
    async loadStudents() {
      this.selectedStudent = null;
      this.showStudentDashboard = false;
      try {
        this.students = await getStudentsForInstructor(this.userUid);
      } catch (error) {
        console.error('Failed to load students:', error);
        this.showSnackbar('Failed to load students', 'error');
      }
    },
    resetStudentDashboard() {
      this.selectedStudent = null;
      this.showStudentDashboard = false;
      this.loadStudents();
    },
    async handleStudentAdded() {
      this.showSnackbar('New student added', 'info');
      await this.loadStudents();
    },
    async handleStudentError() {
      this.showSnackbar('Error when adding student', 'error');
      await this.loadStudents();
    },
    async handleInviteSent() {
      this.showSnackbar('Email invitation sent to student', 'info');
      await this.loadStudents();
    },
    async handleInviteFailed() {
      this.showSnackbar('Email invitation failed to send', 'error');
      await this.loadStudents();
    },
    handleChatClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/chat', '_blank');
      } else {
        this.$router.push('/chat');
      }
    },
    handleLogClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/log', '_blank');
      } else {
        this.$router.push('/log');
      }
    },
    openStudentDashboard(student) {
      this.selectedStudent = student;
      this.showStudentDashboard = true;
    },
    openAddStudent() {
      this.$refs.newStudentOverlay.open(this.userUid, this.userName);
    },
    openUserProfile() {
      this.$refs.userProfile.open(this.userUid);
    },
    openAccountSettings() {
      this.$refs.accountSettings.open(this.isInstructor);
    },
    async signOut() {
      try {
        await this.$store.dispatch('signOut');
        this.$router.push('/');
      } catch (error) {
        console.error('Sign Out Error:', error);
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = '';
      this.$nextTick(() => {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.timeout = 3000;
      });
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters.isLoadingUser && this.isLoading === false;
    },
    user() {
      return this.$store.getters.user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isInstructor() {
      return this.$store.state.user ? this.$store.state.user.isInstructor : false;
    },
    userUid() {
      return this.$store.state.user ? this.$store.state.user.uid : null;
    },
    userEmail() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.displayName : '';
    },
    userPhoto() {
      return this.$store.state.user && this.$store.state.user.photoURL ? this.$store.state.user.photoURL : this.defaultAvatar;
    },
    truncatedUserEmail() {
      const maxLength = 24;
      if (this.userEmail && this.userEmail.length > maxLength) {
        return this.userEmail.substring(0, maxLength - 3) + '...';
      }
      return this.userEmail;
    }
  },
  watch: {
    isLoadingUser(newVal) {
      if (!newVal) {
        this.isLoading = false;
      }
    },
    showStudentDashboard(newVal) {
      if (newVal === true && this.selectedStudent !== null) {
        this.$nextTick(() => {
          this.$refs.studentDetails.open(this.selectedStudent, this.userUid);
        });
      }
    }
  }
}
</script>

<style scoped>
.top-navigation-bar {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
}

.navigation-drawer {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

:deep(.menu-card) {
  background-color: rgba(var(--v-theme-primary), 0.8) !important;
  backdrop-filter: blur(10px);
}

.placeholder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.placeholder-text {
  color: rgb(var(--v-theme-primary));
}

.loading-indicator {
  padding-left: 64px;
}

.card-container {
  padding-block: 10px;
  margin-bottom: 20px;
}

.card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  margin: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Add this line to prevent overflow */
}

.card-item-endorsement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  margin: 10px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; /* Add this line to prevent overflow */
}

.card-item:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-icon {
  width: 50px;
  height: 50px;
  background-color: rgba(var(--v-theme-primary), 0.75);
  color: white;
  border-radius: 50%;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.student-name {
  white-space: normal;
  text-wrap: break-word;
}

.card-endorsement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}

.endorsement-title {
  white-space: normal;
  text-wrap: break-word;
}

.card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.name-placeholder {
  color: rgba(var(--v-theme-primary), 0.5);
}

.rating-chip {
  background-color: rgb(var(--v-theme-primary));
  color: white;
  border-radius: 5px;
}

.type-chip {
  background-color: rgb(var(--v-theme-accent));
  color: white;
  border-radius: 5px;
}

.path-text {
  padding-block: 5px;
}

.card-email {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-owner {
  word-break: break-word;
  padding-top: 10px;
  padding-bottom: 5px;
}

.endorsement-details {
  padding-block: 5px;
}

.fab-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: var(--v-theme-primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .loading-indicator {
    padding-left: 0px;
  }
}

@media (max-width: 600px) {
  .avatar-icon {
    width: 40px;
    height: 40px;
  }

  .fab-button {
    bottom: 20px;
    right: 20px;
  }

  .card-container {
    padding-top: 20px;
    padding-bottom: 80px;
  }
}
</style>
