<!-- src/pages/LogPage.vue -->

<template>
    <v-navigation-drawer 
    expand-on-hover 
    rail
    class="navigation-drawer"
    rail-width="64"
    width="350"
    v-if="!$vuetify.display.mobile"
  >
    <LogNavigation
      :userPhoto="userPhoto"
      :userName="userName"
      :truncatedUserEmail="truncatedUserEmail"
      @openAddFlight="openAddFlight"
      @openUserProfile="openUserProfile"
      @openAccountSettings="openAccountSettings"
      @signOut="signOut"
    />
  </v-navigation-drawer>

  <v-app-bar class="top-navigation-bar" density="compact">
    <v-row class="d-flex align-center">
      <v-col cols="2" class="d-flex justify-center">
        <v-menu
          v-model="menu"
          location="end"
          v-if="$vuetify.display.mobile"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon color="primary" v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card" width="350">
            <LogNavigation
              :userPhoto="userPhoto"
              :userName="userName"
              :truncatedUserEmail="truncatedUserEmail"
              @openAddFlight="openAddFlight"
              @openUserProfile="openUserProfile"
              @openAccountSettings="openAccountSettings"
              @signOut="signOut"
            />
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="8" class="d-flex justify-center">
        <v-tabs v-model="activeTab" color="primary">
          <v-tab @click="handleChatClick">Chat</v-tab>
          <v-tab>Log</v-tab>
          <v-tab v-if="isInstructor" @click="handleInstructClick">Instruct</v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="!$vuetify.display.mobile" cols="2" class="d-flex justify-center">
        <v-btn 
          prepend-icon="mdi-airplane-plus"
          color="primary"
          @click="openAddFlight"
        >
          Add Flight
        </v-btn>
      </v-col>
      <v-col v-else cols="2" class="d-flex justify-center">
        <v-btn 
          icon="mdi-airplane-plus"
          variant="text"
          color="primary"
          @click="openAddFlight"
        >
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-container fluid v-if="!isLoading && flights.length > 0" class="card-container">
    <v-row class="mt-2">
      <v-col cols="12" v-for="flight in flights" :key="flight.id">

        <v-card class="flight-card" color="primary" variant="tonal">
          <v-row>
            <v-col>
              <v-card-title> {{ formatDate(flight.timestamp) }} </v-card-title>
            </v-col>
          </v-row>

          <v-card-text v-if="flight.debriefSummary" class="flight-summary py-2">
            <v-row>
              <v-col v-for="(blurb, index) in splitSummary(flight.debriefSummary)" :key="index" cols="12" class="py-1">
                <v-card variant="tonal" color="primary">
                  <v-card-text>
                    {{ blurb }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else class="flight-summary py-2"> No flight debrief summary to display. </v-card-text>

          <v-card-actions class="card-actions">
            <v-btn variant="text" icon="mdi-delete" color="error" @click="openDeleteFlight(flight)"></v-btn>
            <v-spacer></v-spacer>
            <v-btn variant="flat" color="primary" @click="openViewFlight(flight)">View</v-btn>
          </v-card-actions>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="!isLoading && flights.length == 0" class="placeholder-container">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
          src="@/assets/info-placeholder.png"
          max-width="200"
          class="mx-auto mb-3 rounded"
        ></v-img>
        <v-card-text class="placeholder-text">No flights to display. Add a new flight to get started!</v-card-text>
      </v-col>
    </v-row>
  </v-container>

  <v-overlay 
    v-model="isLoading" 
    class="align-center justify-center"
    persistent
  >
  <v-row justify="center">
    <v-col cols="12" class="loading-indicator">
        <v-progress-circular
            indeterminate
            color="primary"
            size="100"
            width="5"
        > <v-icon>mdi-airplane</v-icon>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>

  <SnackbarNotification 
    :message="snackbar.message" 
    :color="snackbar.color" 
    :timeout="snackbar.timeout" 
    location="top right"
  />
  <UserProfile ref="userProfile" @profileUpdated="() => showSnackbar('User profile updated', 'info')"/>
  <AccountSettings
    :userEmail="userEmail"
    :userName="userName"
    ref="accountSettings" 
    @settingsUpdated="() => showSnackbar('Account settings updated', 'info')"
    @signOut="signOut"
  />
  <AddFlight ref="addFlight" 
    @flightRecordAdded="handleFlightAdded"
    @flightRecordError="() => showSnackbar('Error adding flight record', 'error')"
  />
  <ViewFlight ref="viewFlight"/>
  <DeleteFlight ref="deleteFlight" @deleteFlightRecord="handleFlightDeleted"/>
</template>

<script>
import { getFlights, deleteFlight } from '@/services/api';
import LogNavigation from "@/components/log/LogNavigation.vue";
import UserProfile from "@/components/other/UserProfile.vue";
import AccountSettings from "@/components/other/AccountSettings.vue";
import SnackbarNotification from "@/components/other/SnackbarNotification.vue";
import AddFlight from '@/components/flights/AddFlight.vue';
import ViewFlight from '@/components/flights/ViewFlight.vue';
import DeleteFlight from '@/components/flights/DeleteFlight.vue';

export default {
  name: 'LogPage',
  components: {
    LogNavigation,
    UserProfile,
    AccountSettings,
    SnackbarNotification,
    AddFlight,
    ViewFlight,
    DeleteFlight
  },
  data () {
    return {
      defaultAvatar: require('@/assets/default-avatar.png'),
      assistantAvatar: require('@/assets/orb-animation.png'),
      isLoading: false,
      menu: false,
      activeTab: 1,
      snackbar: {
        message: '',
        color: '',
        timeout: null,
      },
      flights: []
    }
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch('fetchUser'),
      this.loadFlights()
    ]);
    if (!this.isLoadingUser) {
      this.isLoading = false;
    }
  },
  methods: {
    async loadFlights() {
      this.isLoading = true;
      this.flights = await getFlights(this.userUid);
      this.isLoading = false;
    },
    async handleFlightAdded() {
      this.showSnackbar('New flight record added. Nice job!', 'success');
      await this.loadFlights();
    },
    async handleFlightDeleted(flight) {
      await deleteFlight(this.userUid, flight.flightId);
      this.showSnackbar('Flight record successfully deleted', 'info');
      await this.loadFlights();
    },
    handleChatClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/chat', '_blank');
      } else {
        this.$router.push('/chat');
      }
    },
    handleInstructClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/instruct', '_blank');
      } else {
        this.$router.push('/instruct');
      }
    },
    openAddFlight() {
      this.$refs.addFlight.open(this.userUid);
    },
    openViewFlight(flight) {
      this.$refs.viewFlight.open(flight);
    },
    openDeleteFlight(flight) {
      this.$refs.deleteFlight.open(flight);
    },
    openUserProfile() {
      this.$refs.userProfile.open(this.userUid);
    },
    openAccountSettings() {
      this.$refs.accountSettings.open(this.isInstructor);
    },
    async signOut() {
      try {
        await this.$store.dispatch('signOut');
        this.$router.push('/');
      } catch (error) {
        console.error('Sign Out Error:', error);
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = '';
      this.$nextTick(() => {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.timeout = 8000;
      });
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
      });
    },
    splitSummary(summary) {
      return summary.split(/\n\s*\n/).filter(paragraph => paragraph.trim() !== '');
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters.isLoadingUser && this.isLoading === false;
    },
    user() {
      return this.$store.getters.user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isInstructor() {
      return this.$store.state.user ? this.$store.state.user.isInstructor : false;
    },
    userUid() {
      return this.$store.state.user ? this.$store.state.user.uid : null;
    },
    userEmail() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.displayName : '';
    },
    userPhoto() {
      return this.$store.state.user && this.$store.state.user.photoURL ? this.$store.state.user.photoURL : this.defaultAvatar;
    },
    truncatedUserEmail() {
      const maxLength = 24;
      if (this.userEmail && this.userEmail.length > maxLength) {
        return this.userEmail.substring(0, maxLength - 3) + '...';
      }
      return this.userEmail;
    }
  },
  watch: {
    isLoadingUser(newVal) {
      if (!newVal) {
        this.isLoading = false;
      }
    },
    showStudentDashboard(newVal) {
      if (newVal === true && this.selectedStudent !== null) {
        this.$nextTick(() => {
          this.$refs.studentDetails.open(this.selectedStudent, this.userUid);
        });
      }
    }
  }
}
</script>

<style scoped>
.top-navigation-bar {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
}

.navigation-button {
  margin-inline: 10px;
  color: rgb(var(--v-theme-primary));
}

.navigation-drawer {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

:deep(.menu-card) {
  background-color: rgba(var(--v-theme-primary), 0.8) !important;
  backdrop-filter: blur(10px);
}

.placeholder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.placeholder-text {
  color: rgb(var(--v-theme-primary));
}

.loading-indicator {
  padding-left: 64px;
}

.card-container {
  height: 100%;
  margin-bottom: 50px;
}

.flight-card {
  padding: 10px;
  margin-inline: 50px;
}

.notes-section {
  padding-block: 0;
  padding-left: 0;
  font-weight: 500;
}

.flight-summary {
  overflow-y: visible;
}

.flight-notes {
  height: 320px;
  margin: 10px;
  overflow-y: auto;
}

.flight-notes::-webkit-scrollbar {
  width: 5px;
}

.flight-notes::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.flight-notes::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.flight-notes::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.card-actions {
  height: 64px;
}

@media (max-width: 1024px) {
  .loading-indicator {
    padding-left: 0px;
  }

  .flight-card {
    margin-inline: 10px;
  }
}
</style>
