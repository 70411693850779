// src/services/authService.js

import { reactive, readonly } from 'vue';
import { db, auth, analytics } from '/firebaseConfig';
import { 
  onAuthStateChanged, 
  signOut as firebaseSignOut, 
  signInWithPopup, 
  signInWithEmailLink, 
  sendSignInLinkToEmail, 
  GoogleAuthProvider, 
  OAuthProvider, 
  isSignInWithEmailLink,
  setPersistence, 
  browserLocalPersistence 
} from 'firebase/auth';
import store from '../store';
import { Sentry } from '@/plugins/sentry.js';
import { logEvent } from 'firebase/analytics';
import { 
  doc, 
  getDoc, 
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const state = reactive({
  user: null
});

async function recordUserLogin(userUid) {
  const userRef = doc(db, "users", userUid);
  const now = Timestamp.now();

  try {
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, { lastLogin: now });
    }
  } catch (error) {
    Sentry.captureMessage("Error recording user login");
    Sentry.captureException(error);
    console.error("Error recording user login:", error);
  }
}

setPersistence(auth, browserLocalPersistence);

onAuthStateChanged(auth, async (user) => {
  state.user = user;
  store.commit('setUser', user);
  if (user) await recordUserLogin(user.uid);
});

async function googleLogin(router, snackbar) {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    logEvent(analytics, 'user_signed_in_google', { userId: user.uid });
    router.push('/chat');
  } catch (error) {
    handleAuthError(error, snackbar);
  }
}

async function appleLogin(router, snackbar) {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    logEvent(analytics, 'user_signed_in_apple', { userId: user.uid });
    router.push('/chat');
  } catch (error) {
    handleAuthError(error, snackbar);
  }
}

function handleAuthError(error, snackbar) {
  Sentry.captureMessage('Authentication failed');
  Sentry.captureException(error);
  console.error('Authentication failed:', error);

  let message = 'Login failed. Please try again.';
  switch (error.code) {
    case 'auth/popup-closed-by-user':
      message = 'The login popup was closed before completing the sign-in';
      break;
    case 'auth/network-request-failed':
      message = 'Network error. Check your connection and try again.';
      break;
    default:
      message = error.message || 'An unknown error occurred. Please try again.';
  }

  snackbar.message = message;
  snackbar.color = 'error';
}

async function sendSignInLink(email, snackbar) {
  const actionCodeSettings = {
    url: process.env.VUE_APP_BASE_URL,
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    snackbar.message = 'Sign-in link sent to your email';
    snackbar.color = 'success';
  } catch (error) {
    Sentry.captureMessage("Error sending sign-in email link");
    Sentry.captureException(error);
    console.error('Error sending sign-in email link:', error);
    let message = 'Error sending sign-in link. Please try again.';
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'Invalid email. Please check and try again.';
        break;
      case 'auth/network-request-failed':
        message = 'Network error. Check your connection and try again.';
        break;
    }
    snackbar.message = message;
    snackbar.color = 'error';
  }
}

async function completeSignIn(router, snackbar) {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      const user = result.user;
      window.localStorage.removeItem('emailForSignIn');
      logEvent(analytics, 'user_signed_in_email', { userId: user.uid });
      router.push('/chat');
    } catch (error) {
      Sentry.captureMessage("Error completing sign-in");
      Sentry.captureException(error);
      console.error('Error completing sign-in:', error);
      snackbar.message = 'Error completing sign-in. Please try again.';
      snackbar.color = 'error';
    }
  }
}

const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    window.localStorage.removeItem('emailForSignIn');
  } catch (error) {
    Sentry.captureMessage("Error signing out");
    Sentry.captureException(error);
    console.error('Error signing out:', error);
    throw error;
  }
};

export default {
  state: readonly(state),
  isAuthenticated() {
    return !!state.user;
  },
  googleLogin,
  appleLogin,
  handleAuthError,
  sendSignInLink,
  completeSignIn,
  signOut
};
