<!-- src/components/InstructNavigation.vue -->

<template>
  <div class="drawer-content">

    <v-list bg-color="transparent">
      <v-list-item
        :prepend-avatar="userPhoto"
        :title="userName ? userName : 'FlightSense'"
        :subtitle="truncatedUserEmail"
        class="header-content"
      ></v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list bg-color="transparent">
      <v-list-item
        prepend-icon="mdi-account-plus"
        title="Add Student"
        variant="flat"
        base-color="accent"
        rounded="true"
        class="highlight-item"
        @click="openAddStudent"
      >
      </v-list-item>
    </v-list>

    <v-spacer></v-spacer>

    <v-divider></v-divider>

    <v-list bg-color="transparent">
      <v-list-item
        prepend-icon="mdi-account-circle"
        title="User Profile"
        rounded="true"
        class="list-item-footer"
        @click="openUserProfile"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-cog"
        title="Account Settings"
        rounded="true"
        class="list-item-footer"
        @click="openAccountSettings"
      >
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-logout"
        title="Logout"
        rounded="true"
        class="list-item-footer"
        @click="signOut"
      ></v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    userPhoto: String,
    userName: String,
    truncatedUserEmail: String
  },
  emits: [
    'openAddStudent',
    'openUserProfile',
    'openAccountSettings',
    'signOut'
],
  methods: {
    openAddStudent() {
      this.$emit('openAddStudent');
    },
    openUserProfile() {
      this.$emit('openUserProfile');
    },
    openAccountSettings() {
      this.$emit('openAccountSettings');
    },
    signOut() {
      this.$emit('signOut');
    },
  },
};
</script>

<style scoped>
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-content {
  color: rgb(var(--v-theme-accent));
  margin: 4px;
}

.list-item-footer {
  color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  border-radius: 10px;
}

.highlight-item {
  background-color: rgb(var(--v-theme-accent));
  margin-inline: 4px;
  margin-block: 4px;
  border-radius: 10px;
}
</style>
