// firebaseService.js

import { db } from "../../firebaseConfig";
import { storage } from "../../firebaseConfig";
import { 
  doc, 
  getDoc, 
  getDocs,
  setDoc,
  collection,
  updateDoc, 
  query,
  where
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig';
import { logEvent } from 'firebase/analytics';

async function checkIfEmailExists(email, instructorUid) {
  const usersRef = collection(db, "users");
  const instructorRef = doc(db, `instructors/${instructorUid}`);
  const activeStudentsRef = collection(instructorRef, "activeStudents");

  try {
    // Check in 'users' collection
    const usersQuery = query(usersRef, where("email", "==", email));
    const usersSnapshot = await getDocs(usersQuery);
    const emailExistsInUsers = !usersSnapshot.empty;

    // Check in instructor's 'activeStudents' collection
    let studentUid = null;
    if (emailExistsInUsers) {
      studentUid = usersSnapshot.docs[0].id;
    }
    let emailExistsInActiveStudents = false;
    if (studentUid) {
      const activeStudentsQuery = query(activeStudentsRef, where("id", "==", studentUid));
      const activeStudentsSnapshot = await getDocs(activeStudentsQuery);
      emailExistsInActiveStudents = !activeStudentsSnapshot.empty;
    }

    return {
      emailExistsInUsers,
      emailExistsInActiveStudents
    };
  } catch (error) {
    Sentry.captureMessage("Error checking if email exists");
    Sentry.captureException(error);
    console.error("Error checking if email exists:", error);
    return {
      emailExistsInUsers: false,
      emailExistsInActiveStudents: false
    };
  }
}

async function reportInaccurateResponse(reportId, threadId, message, user) {
  const reportRef = doc(db, "reportedResponses", reportId);
  
  const reportDoc = await getDoc(reportRef);
  if (!reportDoc.exists()) {
    await setDoc(reportRef, { reportId: reportId });
  }

  const reportData = {
    reportId: reportId,
    threadId: threadId,
    userId: user,
    message: message,
    timestamp: new Date()
  };

  try {
    await updateDoc(reportRef, reportData);
    logEvent(analytics, 'inaccurate_response_reported', { reportId: reportId });
    return true;
  } catch (error) {
    Sentry.captureMessage("Error reporting inaccurate response");
    Sentry.captureException(error);
    console.error("Error reporting inaccurate response:", error);
    throw error;
  }
}

async function uploadFileToStorage(userId, file) {
  try {
    const fileRef = ref(storage, `corpus/user/${userId}/${file.name}`);
    await uploadBytes(fileRef, file);
    logEvent(analytics, 'custom_file_uploaded', { userUid: userId});
    return { success: true, filename: file.name };
  } catch (error) {
    Sentry.captureMessage("Error uploading custom file");
    Sentry.captureException(error);
    console.error("Error uploading file:", error);
    throw error;
  }
}

async function deleteFileFromStorage(userId, filename) {
  try {
    const fileRef = ref(storage, `corpus/user/${userId}/${filename}`);
    await deleteObject(fileRef);
    logEvent(analytics, 'custom_file_deleted', { userUid: userId});
    return { success: true, filename };
  } catch (error) {
    Sentry.captureMessage("Error deleting custom file");
    Sentry.captureException(error);
    console.error("Error deleting file:", error);
    throw error;
  }
}

export { 
  checkIfEmailExists,
  reportInaccurateResponse,
  uploadFileToStorage,
  deleteFileFromStorage
}
